import { TabType } from '@/components/Header';
import Hepler from '@/components/Helper';
import Layout from '@/components/Layout';
import WhatsAppChat from '@/components/WhatsAppChat';
import BookingByCustomer from '@/containers/BookingByCustomer';
import TourBookingByCustomer from '@/containers/TourBookingByCustomer';
import { TourBookingProvider } from '@/containers/TourBookingByCustomer/providers/TourBookingProvider';
import { Tour } from '@/types/tourBooking';
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ReactElement, useMemo } from 'react';

const Home = (
  _props: InferGetServerSidePropsType<typeof getServerSideProps>,
) => {
  const tabType = useMemo(() => {
    if (typeof _props.query.tab === 'string') {
      return _props.query.tab;
    }
    return undefined;
  }, [_props]);

  if (tabType === TabType.TOUR) {
    return (
      <TourBookingProvider locale={_props.locale} tours={_props.tours}>
        <TourBookingByCustomer tours={_props.tours} />
      </TourBookingProvider>
    );
  }

  return <BookingByCustomer />;
};

Home.getLayout = (page: ReactElement) => (
  <Layout hasBackground={true} hasFooter={true} showTabs>
    {page}
    <WhatsAppChat />
    <Hepler />
  </Layout>
);

Home.type = 'public';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { locale, query } = context;
  let tours: Tour[] = [];
  const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

  const convertImages = (data: any): Tour[] => {
    return data.map((item: any) => ({
      ...item,
      images: JSON.parse(item.images) as string[], // Convert images to array
    }));
  };

  try {
    const list_tour_url = `${API_URL}booking_tour/${locale}/list_tour`;
    const res = await fetch(list_tour_url);

    if (!res.ok) {
      throw new Error(`Failed to fetch, status code: ${res.status}`);
    }

    const data = await res.json();
    tours = convertImages(data);
  } catch (error) {
    console.error('Error fetching tours:', error);
  }

  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', [
        'validate',
        'common',
        'aboutUs',
        'error',
      ])),
      // Will be passed to the page component as props
      tours,
      locale,
      query,
    },
  };
}

export default Home;
