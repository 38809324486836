import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { Box, Tooltip } from '@mui/material';

type Props = {};

export default function WhatsAppChat({ }: Props) {
  const { t } = useTranslation('common');
  const phoneNumber = process.env.NEXT_PUBLIC_WHATSAPP;

  return (
    <Box position="fixed" bottom="120px" right="10px" zIndex={1000}>
      <a
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Tooltip title="WhatsApp" placement='left-start'>
          <Image
            src="/assets/icons/whatsapp.svg"
            alt="Chat with us on WhatsApp"
            width={50}
            height={50}
          />
        </Tooltip>
      </a>
    </Box>
  );
}
