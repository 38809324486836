import useStoreApp from '@/store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowDownIcon } from '../SvgIcons';
import { Span } from '@sentry/nextjs';
import { colors } from '@mui/material';

export default function AboutUs() {
  const { t } = useTranslation('aboutUs');
  const { hotline } = useStoreApp(state => state.globalSlice);
  const [more, setMore] = useState<boolean>(false);
  const makeACall = useCallback(() => {
    window.open(`tel:${hotline}`, '_self');
  }, [hotline]);

  const loadMore = useCallback(() => {
    setMore(true);
  }, []);

  return (
    <AboutUsWrap>
      <Typography component="h1">{t('youKnow')}</Typography>
      <Typography component="h2">{t('about1')}</Typography>

      <AboutMainPoint>
        <Box>
          <Typography component="p">{t('capacity')}</Typography>
          <Typography component="p">
            {t('quantityCapacity', {
              quantity: 13,
            })}
          </Typography>
        </Box>
        <Box>
          <Typography component="p">{t('operatingTime')}</Typography>
          <Typography component="p">
            {t('workingTime', {
              from: '7 AM',
              to: '10 PM',
            })}
          </Typography>
        </Box>
      </AboutMainPoint>
      <Typography component="p">{t('smallNote')}</Typography>

      <MoreInfoBox
        onClick={loadMore}
        sx={{
          display: {
            xs: more ? 'none' : 'flex',
            sm: 'none',
          },
        }}
      >
        <Typography component="p">{t('moreInfo')}</Typography>
        <ArrowDownIcon />
      </MoreInfoBox>

      <AboutFeature
        sx={{
          display: {
            xs: more ? 'block' : 'none',
            sm: 'block',
          },
        }}
      >
        <li>{t('about2')}</li>
        <li>
          <Trans
            i18nKey="aboutUs:about3"
            components={{
              span1: <span onClick={makeACall} />,
            }}
          />
        </li>
      </AboutFeature>
    </AboutUsWrap>
  );
}

const AboutUsWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '32px 24px 24px 24px',
  borderRadius: '16px',
  backgroundColor: theme.palette.white.main,
  fontSize: '14px',
  lineHeight: '22px',

  '& > h1': {
    marginBottom: '20px',
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
  },

  '& > h2': {
    marginBottom: '24px',
    fontSize: '14px',
    lineHeight: '22px',
  },

  '& > p': {
    marginTop: '8px',
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.black[20],
  },
}));

const MoreInfoBox = styled(Box)(({ theme }) => ({
  marginTop: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',

  '& > p': {
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

const AboutMainPoint = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  padding: '14px 20px',
  backgroundColor: theme.palette.success.main,
  display: 'flex',
  gap: '24px',

  '& .MuiBox-root': {
    color: theme.palette.white.main,

    'p:nth-of-type(odd)': {
      fontSize: '12px',
      lineHeight: '14px',
    },

    'p:nth-of-type(even)': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
    },
  },
}));

const AboutFeature = styled('ul')(({ theme }) => ({
  marginTop: '28px',
  marginBottom: '0px',
  paddingInlineStart: '16px',

  '&  li': {
    span: {
      color: '#EA5455',
    },

    '&::marker': {
      fontSize: '18px',
    },
  },
}));
