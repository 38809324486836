import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { Box, keyframes, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {};

export default function Hepler({ }: Props) {
  const { t } = useTranslation('common');
  const requestDeleteAccountUrl = 'https://docs.google.com/forms/d/1jfONR7bECRD_JkX1rqUGw_S5gD902gDHMrw9QWZCv-M';

  return (
    <HelperStyle>
      <a
        href={requestDeleteAccountUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Tooltip title={t('contactUsDelete')} placement='left-start'>
          <ImgHelper
            src="/assets/icons/ic_remove_account.svg"
            alt="Request delete account"
            width={36}
            height={36}
          />
        </Tooltip>
      </a>
    </HelperStyle>
  );
}

const HelperStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '60px',
  right: '16px',
  zIndex: 1001,
  border: '4px solid',
  borderRadius: '50%',
  paddingInline: '6px',
  paddingBlock: '4px',

  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main,

  animation: `${scale} 1s infinite`,
}));


const ImgHelper = styled(Image)(({ theme }) => ({
}));

// TODO: Animation Shake

const scale = keyframes`
  0% { transform:  scale(0.7); }
  50% { transform: scale(0.8); }
  100% { transform: scale(0.7); }
`;
